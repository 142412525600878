window.$ = window.jQuery = window.jquery = require('./jquery-3.2.1.min.js');

//require('./recapture.js');
require('./jquery.tm.avalanche.js');
require('./jquery.tm.horizon.js');
//require('./jquery.tm.snowbridge.js');
require('./jquery.tm.swipe.js');
//require('./jquery.tm.summit.js');
//require('./jquery.tm.counter.js');
require('./jquery.tm.freeze.js');
require('./jquery.tm.equalize.js');
require('./jquery.tm.retinize.js');
//require('./jquery.tm.socialize.js');
require('./template-functions.js');
require('./timber.js');
